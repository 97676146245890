<template>
    <div class="mobile pt25">
        <div class="m_profile p_modal" @click="openMemberModal"></div>
        <div  class="m_item">
            <div class="m_title">가입</div>
            <div class="m_content">2020-05-30 11:11</div>
        </div>
        <div  class="m_item">
            <div class="m_title">아이디</div>
            <div class="m_content">papamiso</div>
        </div>
        <div  class="m_item">
            <div class="m_title">이름</div>
            <div class="m_content">유이름</div>
        </div>
        <div  class="m_item">
            <div class="m_title">생년월일</div>
            <div class="m_content">2000.11.11</div>
        </div>
        <div  class="m_item">
            <div class="m_title">이메일</div>
            <div class="m_content">toystory@gmail.com</div>
        </div>
        <div  class="m_item">
            <div class="m_title">전화번호</div>
            <div class="m_content">010-1234-5678</div>
        </div>
        <div  class="m_item">
            <div class="m_title">직업</div>
            <div class="m_content fw500 c_blue">직업</div>
        </div>
        <div  class="m_item">
            <div class="m_title">소속</div>
            <div class="m_content fw500 c_blue">소속</div>
        </div>
        <div  class="m_item">
            <div class="m_title">알림</div>
            <div class="m_content fw500 c_blue">건축,교직,미술</div>
        </div>
        <input type="textarea" placeholder="자기소개 입력" rows="7"  class="m_textarea">
            <div class="m_title pb5">자기소개</div>
        </input>
    </div>
</template>

<script>
    import util from "@/mixins/util";
    import EventBus from "@/utils/event-bus";
    import {mapState, mapGetters} from "vuex"
    export default {
        name: "MemberDetailPageMentorLayout",
        mixins: [],
        components: {
        },
        props: {
            memberData: {
                type: Object,
                default: () => {return {}},
            },
        },
        data() {
            return {

            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            decodeHtml(html) {
                return util.htmlDecode(html);
            },
            openMemberModal() {
                EventBus.$emit('openMemberInfoModal')
            }
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>