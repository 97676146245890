var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile pt25"},[_c('div',{staticClass:"m_profile p_modal",on:{"click":_vm.openMemberModal}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('input',{staticClass:"m_textarea",attrs:{"type":"textarea","placeholder":"자기소개 입력","rows":"7"}}),_c('div',{staticClass:"m_title pb5"},[_vm._v("자기소개")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("가입")]),_c('div',{staticClass:"m_content"},[_vm._v("2020-05-30 11:11")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("아이디")]),_c('div',{staticClass:"m_content"},[_vm._v("papamiso")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("이름")]),_c('div',{staticClass:"m_content"},[_vm._v("유이름")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("생년월일")]),_c('div',{staticClass:"m_content"},[_vm._v("2000.11.11")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("이메일")]),_c('div',{staticClass:"m_content"},[_vm._v("toystory@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("전화번호")]),_c('div',{staticClass:"m_content"},[_vm._v("010-1234-5678")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("직업")]),_c('div',{staticClass:"m_content fw500 c_blue"},[_vm._v("직업")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("소속")]),_c('div',{staticClass:"m_content fw500 c_blue"},[_vm._v("소속")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_item"},[_c('div',{staticClass:"m_title"},[_vm._v("알림")]),_c('div',{staticClass:"m_content fw500 c_blue"},[_vm._v("건축,교직,미술")])])
}]

export { render, staticRenderFns }